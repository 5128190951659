<script>
	import GlobalVue from '../../helper/Global.vue'
    import Gen from '@helper/Gen';

	export default {
		extends: GlobalVue,
		data() {
			return {
				input: {},
				activemenu: {}
			}
		},
		computed: {},
		mounted() {
			this.$emit("ready", this)
			this.activeMenu()
		},
		methods: {
			activeMenu(){
			    Gen.apirest("/active-menu", {}, (err, resp) => {
                    if (err) console.log(err)
                    this.activemenu = resp.data
                })
            },
			open(params) {
				global.Modal = this
				$(this.$el).modal()
				this.$set(this.input, 'type', "0")
			},
			onSearch() {

			},
			closeModal(){
				console.log("fajar")
				$(this.$el).modal("hide")
			}
		},
		watch: {}
	};
</script>

<template>
	<!-- Pilih Desain-->
	<div class="modal" id="Search" tabindex="-1" role="dialog" aria-labelledby="SearchCenterTitle" aria-hidden="true">
		<div class="modal-dialog   modal-lg" role="document" data-aos="fade-up" data-aos-duration="1000">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLongTitle"></h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true"><i class="icon-line-cross"></i></span>
					</button>
				</div>
				<div class="modal-body" data-aos="fade-up" data-aos-duration="1000">
					<div class="row">
						<div class="col-md-12">
							<div class="age_inform mb_search">
								<h3>{{web.lbl_caps_search}}</h3>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="form-group select_custom">
								<select v-model="input.type" id="template-contactform-service"
									name="template-contactform-service" class="sm-form-control frm_custom valid">
									<option value="0">{{web.lbl_option}}</option>
									<option value="1">Event</option>
									<option value="2">Blog</option>
									<option value="3" v-if="activemenu.wine">Wine</option>
									<option value="4" v-if="activemenu.spirit">Spirit - Liquer</option>
									<option value="5" v-if="activemenu.water">Water</option>
									<option value="6" v-if="activemenu.other">Lainnya</option>
								</select>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<input type="text" v-model="input.search" name="" class="frm_custom" value=""
									:placeholder="web.lbl_search" />
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer" style="display: block;">
					<p class="text-center">
						<router-link v-if="input.type=='1'" data-dismiss="modal" :to="{name:'Event', query:{search:input.search}}" class="btn_act d-md-inline-block">Search</router-link>
						<router-link v-if="input.type=='2'" data-dismiss="modal" :to="{name:'Blog',query:{search:input.search}}" class="btn_act d-md-inline-block">Search</router-link>
						<router-link v-if="input.type=='3'" data-dismiss="modal" :to="{name:'Product',query:{search:input.search}}" class="btn_act d-md-inline-block">Search</router-link>
						<router-link v-if="input.type=='4'" data-dismiss="modal" :to="{name:'Product2',query:{search:input.search}}" class="btn_act d-md-inline-block">Search</router-link>
						<router-link v-if="input.type=='5'" data-dismiss="modal" :to="{name:'Product3',query:{search:input.search}}" class="btn_act d-md-inline-block">Search</router-link>
						<router-link v-if="input.type=='6'" data-dismiss="modal" :to="{name:'Product4',query:{search:input.search}}" class="btn_act d-md-inline-block">Search</router-link>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>